import anime, { stagger } from 'animejs';
import barba from "@barba/core";
import LocomotiveScroll from 'locomotive-scroll';
import EmblaCarousel from 'embla-carousel'
import Autoplay from 'embla-carousel-autoplay'
import Plyr from 'plyr';

import WindowUtils from './utils/WindowUtils';
import Utils from "./utils/Utils";
import Type8 from './utils/Type8';

import LogoRender from './vendors/LogoRender';

import { setupPrevNextBtns, listenForPrevBtnClick, listenForNextBtnClick, disablePrevNextBtns, playPauseVideo } from "./utils/PrevNextButtons";

const WU = new WindowUtils();

let scroll;
const cursor = document.querySelector('.cursor');
const cursorContent = document.querySelector('.cursor_content');
let render;

let prevPageSection = null;
let nextPageSection = null;

render = new LogoRender();

const updateCursorValue = (value) => {
    cursorContent.innerHTML = value;
};

let onWheelIntro = (event) => {
    if (event.deltaY > 5 || event.deltaY < -5) {
        clearTimeout(introTimeout)
        goToIntro();
        document.removeEventListener("wheel", onWheelIntro);
    }
};

/* ------------------------------------ */
/* ------ Locomotive scroll init ------ */
/* ------------------------------------ */
const createScroll = (container) => {
    let elt = container.querySelector('[data-scroll-container]');

    scroll = new LocomotiveScroll({
        el: elt,
        smooth: true,
        getSpeed: true,
        getDirection: true,
        gestureDirection: 'vertical',
        tablet: {
            smooth: false
        }
    });

    scroll.stop();
    
    scroll.on('call', (value, way, obj) => {
        // if (WU.isXS()) return;

        let calls = (typeof value === 'string') ? [value] : value;
        scrollAnimations(calls, way, obj);
    });

    return scroll;
};

const scrollAnimations = (calls, way, obj) => {
    calls.forEach(call => {
        if (call === 'sectionDate' && way === 'enter') {
            let date = obj.el.getAttribute('data-year');
            document.querySelector('.currentDate').innerHTML = date;
            document.querySelector('.navbar_title').innerHTML = date;

        } else if (call === 'sectionTitle' && way === 'enter') {
            let title = obj.el.getAttribute('data-title');
            document.querySelector('.navbar_title').innerHTML = title;
            prevPageSection = Utils.getPreviousSibling(obj.el, '.pSection');
            nextPageSection = Utils.getNextSibling(obj.el, '.pSection');
            // console.log(prevPageSection, obj.el, nextPageSection);
        } else if (call === 'toggleVideo') {
            if (way === 'enter') {
                obj.el.play();
            } else {
                obj.el.pause();
            }
        } else if (call === 'updateRenderPosition' && way === 'enter') {
            if (!render) return;
            
            let value = obj.el.getAttribute('data-value');
            render.setPosition(value);
            render.show();
        } else if (call === 'showRender' && way === 'enter') {
            if (!render) return;

            render.show();
        } else if (call === 'hideRender' && way === 'enter') {
            if (!render) return;

            render.hide();
        } else if (call === 'introStep' && way === 'enter') {
            let intro = document.querySelector('.pSection--intro');
            let value = obj.el.getAttribute('data-value');
            if (intro && value) {
                intro.setAttribute('data-step', value);
            }
        }
    });
};

const loadImages = (parent = document) => {
    parent.querySelectorAll('.imageRender:not(.imageRender--loaded)').forEach((img) => {
        let url = img.getAttribute('data-url');
        let cover = img.querySelector('.imageRender_cover');

        Utils.loadImage(url).then(() => {
            cover.style.backgroundImage = 'url(' + url + ')';
            img.classList.add('imageRender--loaded');
        });
    });
};

const isHome = () => { return document.body.classList.contains('home'); }
const isExpo = () => { return document.body.classList.contains('exposition'); }
const is404 = () => { return document.body.classList.contains('page404'); }

let scrollTop = () => {
    if (!scroll) return;

    scrollTo('top');
};

let videoPlayers = [];
const initVideoPlayers = () => {
    Array.from(document.querySelectorAll('.videoPlayer')).map((p) => {
        let player = new Plyr(p);
        videoPlayers.push(player);

        player.on('ready', (event) => {
            scroll.update();
        });
    });
};
const stopVideoPlayers = () => {
    videoPlayers.forEach(p => {
        p.pause();
    });
};

const scrollTo = (elt, duration = 1000, callback = null, easing = [0.90, 0.00, 0.00, 1.00]) => {
    scroll.scrollTo(elt, { duration: duration, easing: easing, callback: callback });
};

const goToIntro = () => {
    scrollTo('.pSection_call--introContent', 800, () => { scroll.start() });
};

const page = () => {

    if (isHome() && !WU.isXS() && !WU.isSM() && !WU.isMD()) {
        introTimeout = setTimeout(() => {
            goToIntro();
        }, 6000);

        document.addEventListener("wheel", onWheelIntro);
    }

    new Type8();
    document.documentElement.classList.remove('is-locked');
    
    loadImages();

    initVideoPlayers();

    setTimeout(() => {
        scroll.update();
    }, 3000);

    // MENU
    let prevRenderPosition = null;
    if (document.querySelector('.menuButton')) {
        document.querySelector('.menuButton').addEventListener('click', () => {
            prevRenderPosition = render.currentState;
            document.querySelector('.menu').classList.add('visible');
            if (WU.isSM() || WU.isXS()) {
                render.setPosition('top');
            } else {
                render.setPosition('center');
            }
            render.show();
            
            setTimeout(() => {
                if (WU.isSM() || WU.isXS()) {
                    render.setPosition('top');
                } else {
                    render.setPosition('center');
                }
            }, 500);

            if (scroll) {
                scroll.stop();
            }

            document.documentElement.classList.add('is-locked');

        });
    }
    
    if (document.querySelector('.menu_close')) {
        document.querySelector('.menu_close').addEventListener('click', () => {
            document.querySelector('.menu').classList.remove('visible');
            render.setPosition(prevRenderPosition);

            if (scroll) {
                scroll.start();
                scroll.update();
            }

            document.documentElement.classList.remove('is-locked');
        });
    }

    // document.querySelector('.navbar_button--prev').addEventListener('click', () => {
    //     if (scroll && prevPageSection) {
    //         scrollTo(prevPageSection, 1000, null, [0.90, 0.00, 0.00, 1.00] );
    //     }
    // });

    // document.querySelector('.navbar_button--next').addEventListener('click', () => {
    //     if (scroll && nextPageSection) {
    //         scrollTo(nextPageSection, 1000, null, [0.90, 0.00, 0.00, 1.00] );
    //     }
    // });
    
    // if (document.querySelector('.menu_bg')) {
    //     document.querySelector('.menu').querySelectorAll('a[data-transition-bg]').forEach((link) => {
    //         link.addEventListener('mouseenter', () => {
    //             let bg = link.getAttribute('data-transition-bg');
    //             console.log(bg);
    //             document.querySelector('.menu_bg').style.backgroundColor = bg;
    //         });
    //         link.addEventListener('mouseleave', () => {
    //             document.querySelector('.menu_bg').style.backgroundColor = 'transparent';
    //         });
    //     });
    // }

    if (document.querySelector('.scrollTop')) {
        document.querySelector('.scrollTop').addEventListener('click', scrollTop);
    }


    // NEXT SECTION
    document.querySelectorAll('.nextSectionButton').forEach((btn) => {
        btn.addEventListener('click', () => {
            let selector = btn.hasAttribute('data-target') ? btn.getAttribute('data-target') : '.pSection';
            let parentSection = btn.closest('.pSection');
            let nextSection = Utils.getNextSibling(parentSection, selector);
            let footer = document.querySelector('.pSection--sales');
    
            if (nextSection) {
                scrollTo(nextSection);
            } else if (footer) {
                scrollTo(footer);
            }
        });
    })


    // ----------------------------------------------
    // Cursor ---------------------------------------
    // ----------------------------------------------
    window.addEventListener('mousemove', e => {
        cursor.style.transform = 'translate(' + e.clientX + 'px,' + e.clientY + 'px)';
    });

    document.addEventListener('mouseleave', () => {
        cursor.classList.remove('cursor--visible');
    });

    document.querySelectorAll('[data-cursor]').forEach((elt) => {
        elt.addEventListener('mouseenter', (e) => {
            let value = elt.getAttribute('data-cursor');
            cursorContent.innerHTML = value;
            cursor.classList.add('cursor--visible');
            document.body.classList.add('hasCursor');
        });
        elt.addEventListener('mouseleave', () => {
            cursor.classList.remove('cursor--visible');
            document.body.classList.remove('hasCursor');
        });
    });

    if (document.querySelector('.expoCarouselWrap')) {
        const hpEmblaExposWrap = document.querySelector('.expoCarouselWrap');
        const hpEmblaExposNode = hpEmblaExposWrap.querySelector('.embla');
        const hpEmblaExposPrev = hpEmblaExposWrap.querySelector('.expoCarousel_nav--prev');
        const hpEmblaExposNext = hpEmblaExposWrap.querySelector('.expoCarousel_nav--next');
        const hpEmblaExposOptions = { loop: true };
        const hpEmblaExposPlugins = [];
        const hpEmblaExpos = EmblaCarousel(hpEmblaExposNode, hpEmblaExposOptions, hpEmblaExposPlugins);
        setupPrevNextBtns(hpEmblaExposPrev, hpEmblaExposNext, hpEmblaExpos);

        hpEmblaExpos.on("select", disablePrevNextBtns);
        hpEmblaExpos.on("init", disablePrevNextBtns);
        hpEmblaExpos.on("init", () => { scroll.update(); });
    }

    if (document.querySelector('.galleryCarouselWrap')) {
        const galleryEmblaWrap = document.querySelector('.galleryCarouselWrap');
        const galleryEmblaNode = galleryEmblaWrap.querySelector('.embla');
        const galleryEmblaPrev = galleryEmblaWrap.querySelector('.galleryCarousel_nav--prev');
        const galleryEmblaNext = galleryEmblaWrap.querySelector('.galleryCarousel_nav--next');
        const galleryEmblaOptions = { loop: true };
        const galleryEmblaAutoplay = Autoplay(
            { delay: 8000, stopOnInteraction: false },
            (emblaRoot) => emblaRoot.parentElement
        );
        const galleryEmblaPlugins = [galleryEmblaAutoplay];
        const galleryEmbla = EmblaCarousel(galleryEmblaNode, galleryEmblaOptions, galleryEmblaPlugins);
        listenForPrevBtnClick(galleryEmblaPrev, galleryEmbla, galleryEmblaAutoplay);
        listenForNextBtnClick(galleryEmblaNext, galleryEmbla, galleryEmblaAutoplay);

        galleryEmbla.on("select", disablePrevNextBtns);
        galleryEmbla.on("init", disablePrevNextBtns);
        galleryEmbla.on("init", () => { scroll.update(); });

        let onUpdate = () => {
            let current = galleryEmbla.selectedScrollSnap();
            playPauseVideo(galleryEmbla.slideNodes(), current);
        };
        
        galleryEmbla.on("init", onUpdate);
        galleryEmbla.on("select", onUpdate);
    }
};

(function () {

    // new FPS();

    if (!is404()) {
        createScroll(document);
    }
    page();


    let defaultLoadingBg = '#A70151';
    let transitionBg;
    let loadingDOM = document.querySelector('.loadingScreen');

    setTimeout(() => {
        scroll.update();
        anime({
            targets: loadingDOM,
            opacity: [1, 0],
            duration: 400,
            easing: "linear",
            complete: () => { 
                loadingDOM.style.display = 'none';
            }
        });
    }, 500);

    barba.hooks.beforeLeave((data) => { })

    barba.hooks.beforeEnter((data) => {
        scrollTop();
        if (scroll) { scroll.destroy(); }
        scroll = createScroll(data.next.container);
    });

    barba.hooks.enter((data) => {
        setNewAttrs(data.next.html);
    });

    barba.hooks.afterEnter((data) => {
        if (!isHome()) {
            scroll.start();
        }
    });

    barba.hooks.after(() => {
        scroll.update();
    });

    barba.init({
        debug: false,
        timeout: 10000,
        preventRunning: true,
        prevent: ({ el, event }) => {
            transitionBg = el.getAttribute('data-transition-bg') ? el.getAttribute('data-transition-bg') : defaultLoadingBg;
            loadingDOM.style.background = transitionBg;
            
            return false;
        },
        transitions: [
            {
                name: 'default',
                leave(data) {
                    return new Promise(resolve => {
                        anime.set(loadingDOM, { translateY: '101%', opacity: 1 });
                        loadingDOM.style.display = 'block';

                        anime({
                            targets: loadingDOM,
                            translateY: ['101%', '0%'],
                            duration: 1200,
                            easing: "easeOutExpo",
                            complete: () => { 
                                resolve();
                                document.querySelector('.menu').classList.remove('visible');
                            }
                        });
                    });
                },
                enter(data) {
                    let done = this.async();
                    Utils.setNewAttrs(data.next.html);
                    done();

                    setTimeout(() => {
                        page();
                    }, 600);

                    anime({
                        targets: loadingDOM,
                        opacity: [1, 0],
                        duration: 400,
                        delay: 600,
                        easing: "linear",
                        complete: () => { 
                            loadingDOM.style.display = 'none';
                        }
                    });
                }
            }
        ]
    });

})();