import Utils from "../utils/Utils";

class LogoRender {
    
    constructor() {
        this.currentState = null;
        
        this.settings = {
            left: {
                positions: [
                    { top: -4, left: 0 },
                    { top: 3, left: -1 },
                    { top: 62, left: 3 },
                    { top: 24, left: 2 },
                    { top: 27, left: 5 },
                    { top: 70, left: 2 },
                    { top: 45, left: -2 },
                ]
            },
            right: {
                positions: [
                    { top: -4, left: 87 },
                    { top: 3, left: 86 },
                    { top: 62, left: 90 },
                    { top: 24, left: 89 },
                    { top: 27, left: 92 },
                    { top: 70, left: 89 },
                    { top: 45, left: 85 },
                ]
            },
            center: {
                positions: [
                    { top: -4, left: 45 },
                    { top: 3, left: 44 },
                    { top: 62, left: 48 },
                    { top: 24, left: 47 },
                    { top: 27, left: 50 },
                    { top: 70, left: 47 },
                    { top: 45, left: 43 },
                ]
            },
            top: {
                positions: [
                    { top: -9, left: 70 },
                    { top: -18, left: 84 },
                    { top: 5, left: 83 },
                    { top: -24, left: 57 },
                    { top: 7, left: 62 },
                    { top: -15, left: 69 },
                    { top: -3, left: 88 },
                ]
            }
        }

        this.items = document.querySelectorAll('.logoRender_item');
    }

    setPosition (position) {
        if (position == this.currentState) return;

        this.currentState = position;

        let positions = this.settings[position].positions;
        this.items.forEach((item, index) => {
            item.style.left = this.variation(positions[index].left, 2) + "vw";
            item.style.top = this.variation(positions[index].top, 2) + "vh";
        });
    }

    variation (value, modifier) {
        return value + Utils.randomFloat(modifier * -1, modifier);
    }

    hide () {
        document.querySelector('.logoRender').classList.add('hidden');
    }

    show () {
        document.querySelector('.logoRender').classList.remove('hidden');
    }
    
}

export default LogoRender;