import anime from 'animejs';

class Type8 {
    
    constructor() {
        this.k = ['Space','Space','Space','Space','Space','Space','Space','Space'];
        this.n = 0;
        this.stepShowCount = 2;
        this.timeout;
        this.disabled = false;
        this.DOM = {
            parent: document.querySelector('.type8credits'),
            bg: document.querySelector('.type8credits_bg'),
            box: document.querySelector('.type8credits_box'),
            close: document.querySelector('.type8credits_close'),
            video: document.querySelector('.type8credits_video video'),
            count: document.querySelector('.type8credits_count'),
            counter: document.querySelector('.type8credits_count > span'),
            links: document.querySelectorAll('.type8_open')
        };

        // document.addEventListener('keyup', (e) => this.onKeyup(e));

        this.DOM.links.forEach((link) => {
            link.addEventListener('click', (e) => {
                e.preventDefault();
                this.open()
            });
        })

        this.DOM.bg.addEventListener('click', () => this.close());
        this.DOM.close.addEventListener('click', () => this.close());
    }

    onKeyup (e) {
        if (this.disabled) return;

        if (e.code === this.k[this.n++]) {
            if (this.n == this.stepShowCount){
                this.showCounter();
                this.popCounter();
            } else if (this.n > this.stepShowCount) {
                this.popCounter();
            }

            this.DOM.counter.innerHTML = this.n;

            if(this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(()=> {
                this.n = 0;
                this.hideCounter();
                this.DOM.counter.innerHTML = this.n;
            }, 2000)
            
            if (this.n === this.k.length) {
                this.open();
                this.hideCounter();
                this.n = 0;
                return false;
            }
        }
        else {
            if (this.n == 0) {
                this.hideCounter();
            }

            this.n = 0;
            this.DOM.counter.innerHTML = this.n;
        }
    }

    open () {
        this.disabled = true;

        let TL = anime.timeline({
            easing: 'easeOutExpo',
            duration: 800
        });

        this.DOM.parent.classList.add('visible');
        this.DOM.video.play();
        
        TL.add({
            targets: this.DOM.bg,
            opacity: [0, 1],
            duration: 400,
            easing: 'linear'
        });

        TL.add({
            targets: this.DOM.box,
            opacity: [0, 1],
            translateY: [80, 0]
        });
    }

    close () {
        this.disabled = false;

        let TL = anime.timeline({
            easing: 'easeOutExpo',
            duration: 400,
            complete: () => {
                this.DOM.parent.classList.remove('visible');
            }
        });

        this.DOM.video.pause();
        
        TL.add({
            targets: this.DOM.bg,
            opacity: [1, 0],
            duration: 400,
            easing: 'linear'
        }, 0);

        TL.add({
            targets: this.DOM.box,
            opacity: [1, 0],
            translateY: [0, 80]
        }, 0);
    }

    showCounter () {
        anime({
            targets: this.DOM.count,
            opacity: [0, 1],
            duration: 400,
            easing: 'linear'
        });
    }

    hideCounter () {
        anime({
            targets: this.DOM.count,
            opacity: [1, 0],
            duration: 400,
            easing: 'linear'
        });
    }

    popCounter () {
        anime({
            targets: this.DOM.count,
            scale: [1, 1.2],
            duration: 50,
            easing: 'linear'
        });
        anime({
            targets: this.DOM.count,
            scale: [1.2, 1],
            duration: 50,
            delay: 50,
            easing: 'linear'
        });
    }
}

export default Type8;